import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Hero from '../components/Hero'
import ContactCard, { ContactCardGrid } from '../components/ContactCard'
import ContactBox from '../components/ContactBox'

import imageHelper from '../helpers/image-helper'

export default function ContactTemplate ({ data }) {
  if (!data || !data.ievv) {
    return <p>Something went wrong...</p>
  }

  const { title, preamble, heroImage } = data.ievv

  const employees = get(data, 'employees.edges', []).map(
    ({ node: { userId, category } }) => ({ userId, category }),
  )

  const categories = get(data, 'categories.edges', [])
    .map(({ node }) => node)
    .map(({ pageId, title }) => ({
      id: pageId,
      title,
      employees: employees.filter(({ category }) => category === pageId),
    }))

  // Handle leftovers
  const leftovers = employees.filter(({ category }) => !category)
  if (leftovers.length) {
    categories.push({
      id: 0,
      employees: leftovers,
    })
  }

  const getImage = imageHelper(data)
  const hero = heroImage ? getImage(heroImage) : {}

  return (
    <>
      <Hero {...hero} preamble={preamble} title={title} />

      <ContactBox />

      <div>
        {categories.map(({ title, id, employees }) => (
          <ContactCardGrid key={id} title={title} full>
            {employees.map(({ userId }) => (
              <ContactCard key={userId} id={userId} aspect={[3, 4]} />
            ))}
          </ContactCardGrid>
        ))}
      </div>
    </>
  )
}

ContactTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query($pageId: String!) {
    ievv(id: { eq: $pageId }) {
      title
      heroImage
      preamble
      images {
        id
        alt
        image {
          name
        }
      }
    }
    ...GetImages
    employees: allEmployee {
      edges {
        node {
          userId
          category
        }
      }
    }
    categories: allIevv(
      filter: {
        pagetypeid: { eq: "ncl_backend_pageframework.EmployeeCategory" }
      }
    ) {
      edges {
        node {
          title
          pageId
        }
      }
    }
  }
`
