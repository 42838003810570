import './index.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import { StaticQuery, graphql } from 'gatsby'
import { phoneFormat } from '../../helpers/format'

import Container from '../Container'

const bem = new BEMHelper('contact-box')

export default function ContactBox() {
  return (
    <StaticQuery
      query={graphql`
        query {
          contact: ievv(
            pagetypeid: { eq: "ncl_backend_pageframework.GlobalContactInfo" }
          ) {
            contactData {
              phone
              phone_hotline
              emails
              address {
                location
                url
                postbox
              }
            }
          }
        }
      `}
      render={({ contact }) => {
        if (!contact) {
          return null
        }

        return (
          <aside {...bem('')}>
            <Container nodeType="div" large {...bem('content')}>
              <section {...bem('section')}>
                <h2 {...bem('title')}>Call us</h2>
                <a
                  href={`tel:${contact.contactData.phone}`}
                  {...bem('link', 'large')}
                >
                  {phoneFormat(contact.contactData.phone, 'office')}
                </a>
                {contact.contactData.phone_hotline && (
                  <p {...bem('small')}>
                    Call our duty phone on{' '}
                    <a
                      href={`tel:${contact.contactData.phone_hotline}`}
                      {...bem('link')}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {phoneFormat(contact.contactData.phone_hotline)}
                    </a>{' '}
                    for inquiries outside office hours.
                  </p>
                )}
              </section>

              <section {...bem('section')}>
                <h2 {...bem('title')}>Write us</h2>

                <ul {...bem('list')}>
                  {contact.contactData.emails.map(email => (
                    <li key={email} {...bem('item')}>
                      <a href={`mailto:${email}`} {...bem('link')}>
                        {email}
                      </a>
                    </li>
                  ))}
                </ul>
              </section>

              <section {...bem('section')}>
                <h2 {...bem('title')}>Meet us</h2>
                <a href={contact.contactData.address.url} {...bem('link')}>
                  {contact.contactData.address.location}
                </a>
              </section>

              <section {...bem('section')}>
                <h2 {...bem('title')}>Send us</h2>
                <p>{contact.contactData.address.postbox}</p>
              </section>
            </Container>
          </aside>
        )
      }}
    />
  )
}
